define("discourse/plugins/discourse-category-experts/discourse/components/endorsement-button", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/discourse-category-experts/discourse/components/modal/endorse-user", "@ember/template-factory"], function (_exports, _component, _object, _service, _decorators, _endorseUser, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.disabled}}
    {{#if this.endorsements}}
      <div class="category-expert-existing-endorsements">
        {{i18n
          "category_experts.existing_endorsements"
          count=this.endorsementsCount
        }}
        <DButton
          @action={{action "openEndorsementModal"}}
          class="btn-flat category-expert-endorse-edit"
          @label="category_experts.edit"
        />
      </div>
    {{else}}
      <DButton
        @action={{action "openEndorsementModal"}}
        class="category-expert-endorse-btn"
        @label="category_experts.endorse"
      />
    {{/if}}
  {{/unless}}
  */
  {
    "id": "+OTXtXAZ",
    "block": "[[[41,[51,[30,0,[\"disabled\"]]],[[[41,[30,0,[\"endorsements\"]],[[[1,\"    \"],[10,0],[14,0,\"category-expert-existing-endorsements\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"category_experts.existing_endorsements\"],[[\"count\"],[[30,0,[\"endorsementsCount\"]]]]]],[1,\"\\n      \"],[8,[39,3],[[24,0,\"btn-flat category-expert-endorse-edit\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0],\"openEndorsementModal\"],null],\"category_experts.edit\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[24,0,\"category-expert-endorse-btn\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0],\"openEndorsementModal\"],null],\"category_experts.endorse\"]],null],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"unless\",\"if\",\"i18n\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/components/endorsement-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    modal: (0, _service.service)(),
    tagName: "",
    disabled: true,
    user: null,
    categoriesAllowingEndorsements: null,
    init() {
      this._super(...arguments);
      if (!this.siteSettings.enable_category_experts || !this.currentUser || this.currentUser.id === this.user.id || this.user.suspended) {
        return;
      }
      this.set("categoriesAllowingEndorsements", this.site.categories.filter(c => c.allowingCategoryExpertEndorsements));
      if (this.categoriesAllowingEndorsements.length) {
        this.set("disabled", false);
      }
    },
    endorsements(categoryExpertEndorsements) {
      let category_ids = this.categoriesAllowingEndorsements.map(c => c.id);
      let endorsements = categoryExpertEndorsements.filter(endorsement => {
        return category_ids.includes(endorsement.category_id);
      });
      this.set("endorsementsCount", endorsements.length);
      return endorsements;
    },
    openEndorsementModal() {
      if (this.close) {
        this.close();
      }
      if (this.location) {
        this.appEvents.trigger("category-experts:endorse-clicked", {
          location: this.location,
          user_id: this.currentUser.id,
          endorsed_user_id: this.user.id
        });
      }
      this.modal.show(_endorseUser.default, {
        model: {
          user: this.user,
          endorsements: this.endorsements,
          location: this.location
        }
      });
    }
  }, [["method", "endorsements", [(0, _decorators.default)("user.category_expert_endorsements")]], ["method", "openEndorsementModal", [_object.action]]])));
});